import React from 'react';
import Container from '@components/Container';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import Related from '@components/IndustryDataType/Related';
import { industries } from '@constants/industriesAndDatatypes';
import SectionStayConnectedForBlog from '../../Blog/SectionStayConnectedForBlog';
import AISCapabilities from './AISCapabilities';
import AISCloudAnalysis from './AISCloudAnalysis';
import Jumbotron from '../common/Jumbotron';
import AISResources from './AISResources';
import AISServiceCombination from './AISServiceCombination';
import './AISPage.scss';
import shareImage from './assets/ais-share-img.jpg';
import backgroundImage from './assets/ais-background.jpg';

const AISPage: React.FC = () => {
  return (
    <IndexLayout
      pageName="ais"
      helmet={{
        title: 'TileDB - Manage, distribute and analyze AIS data at scale',
        description: 'Precise vessel location and time-series analytics.',
        shareImage: {
          url: shareImage,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent" />
      <main className="main AISPage">
        <Jumbotron
          title="Manage, distribute and analyze AIS data at scale"
          subtitle="Precise vessel location and time-series analytics."
          backgroundImage={backgroundImage}
        />
        <AISCloudAnalysis />
        <AISCapabilities />
        <AISServiceCombination />
        <AISResources />
        <Related items={[industries.maritime, industries.defense, industries.earthObservation, industries.reinsurance]} />
        <Container>
          <SectionStayConnectedForBlog />
        </Container>
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default AISPage;
