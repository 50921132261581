import * as React from 'react';
import Typography from '@components/Typography';
import './ResourceItem.scss';
import arrowRight from './arrow.svg';

type Props = {
  title: string;
  text: React.ReactNode;
  linkText: string;
  to: string;
  icon: string;
};

const ResourceItem: React.FC<Props> = ({ title, text, linkText, to, icon }) => (
  <a className="ResourceItem" href={to} target="_blank" rel="noreferrer">
    <div className="ResourceItem__top">
      <Typography color="brand-900" className="ResourceItem__title" as="h4" fontSize="heading-4" fontWeight="bold">
        {title}
      </Typography>
      <div className="ResourceItem__icon-wrapper">
        <div className="ResourceItem__icon-container">
          <img className="ResourceItem__icon" src={icon} alt={`${title} icon`} />
        </div>
      </div>
    </div>
    <Typography className="ResourceItem__text" fontSize="body" as="p" color="neutral-600">
      {text}
    </Typography>
    <Typography color="brand-800" fontWeight="semi-bold" className="ResourceItem__link">
      {linkText} &nbsp;
      <img className="ResourceItem__arrow" src={arrowRight} alt="arrow right" />
    </Typography>
  </a>
);

export default ResourceItem;
