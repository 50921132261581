import React from 'react';
import Capabilities from '../../common/Capabilities';
import analysisReady from './assets/analysis-ready.png';
import rapidSlicing from './assets/rapid-slicing.png';
import discover from './assets/discover.png';
import interoperability from './assets/interoperability.png';
import marketplace from './assets/marketplace.png';
import visualize from './assets/visualize.png';
import datasets from './assets/datasets.png';

const capabilities = [
  {
    title: 'Access analysis-ready AIS data',
    description:
      'No more CSV downloads. Use TileDB to access AIS datasets on cloud object storage and query directly using native coordinates. Efficiently scale large computations in the cloud where the data resides.',
    icon: analysisReady,
  },
  {
    title: 'Time-series for maritime analytics',
    description:
      'Take advantage of built-in support for AIS time-series data, enabling fast track analysis and density mapping. Index using precise real numbers and datetime objects. Scale analytics to ML-powered dark ship detection, all on a single platform.',
    icon: rapidSlicing,
  },
  {
    title: 'Discover, share & collaborate',
    description:
      'Automatically catalog AIS datasets, notebooks, and all associated metadata for advanced discoverability and governance. Version your work, including notebooks and ML models. Securely share with operations teams, supply chain analysts and government authorities.',
    icon: discover,
  },
  {
    title: 'Enable interoperability end-to-end',
    description:
      'Scale way beyond the capabilities of other databases, like Postgres and GeoMesa. TileDB integrates with existing workflows at every stage of your research — from SQL to R & Python Jupyter notebooks.',
    icon: interoperability,
  },
  {
    title: 'Launch your marketplace',
    description:
      'Sell your data with no additional infrastructure or cost. Built-in marketplace features allow you to develop derived data products and monetize them in a new economic model that accommodates data producers and consumers alike.',
    icon: marketplace,
  },
  {
    title: 'Visualize with ease',
    description:
      'Gain the flexibility you need to power your analysis. TileDB Cloud comes with dashboards and notebooks that can be easily customized to use specialized packages for running unique visualizations using Datashader, HoloViews, and your own custom packages.',
    icon: visualize,
  },
  {
    title: 'Fuse massive datasets',
    description:
      'Avoid complex pipelines when combining AIS with other data types like SAR and VIIRS. Join all data types in a single data platform based on multi-dimensional arrays. Query across multiple index ranges for flexible analysis and visualization.',
    icon: datasets,
  },
];

const LidarCapabilities = () => {
  return <Capabilities capabilities={capabilities} title="We reimagined your AIS data workflow with TileDB Cloud" />;
};

export default LidarCapabilities;
