import classNames from 'classnames';
import * as React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import ResourceItem from './ResourceItem/ResourceItem';
import './Resources.scss';

interface Item {
  title: string;
  text: React.ReactNode;
  linkText: string;
  link: string;
  icon: string;
}

type Props = {
  items: Item[];
  className?: string;
};

const Resources: React.FC<Props> = (props) => {
  const { items = [], className } = props;

  return (
    <Container className={classNames('LidarResources', className)}>
      <Typography fontSize="heading-1" fontWeight="bold" align="center" as="h2" className="mb-12" color="neutral-900">
        Resources
      </Typography>
      <div className="LidarResources__columns">
        {items.map((itm: Item) => {
          const { title, text, linkText, link, icon } = itm;

          return <ResourceItem key={title} title={title} text={text} linkText={linkText} to={link} icon={icon} />;
        })}
      </div>
    </Container>
  );
};

export default Resources;
