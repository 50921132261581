import React from 'react';
import links from '@constants/links';
import Resources from '../../common/Resources';
import bookIcon from './assets/Book.svg';
import videoIcon from './assets/video.svg';
import tutorialIcon from './assets/Grad-hat.svg';

const items = [
  {
    title: 'Documentation',
    text: 'Have a technical question? Check out our documentation, dig into our support for various geospatial libraries and reference materials for Python, and many other languages.',
    linkText: 'Read the docs',
    link: links.docPages.main,
    icon: bookIcon,
  },
  {
    title: 'Video',
    text: (
      <>
        Watch the replay! In <strong>"Dark Ship Detection, No Doubt: Fusing AIS, VIIRS and SAR via TileDB & ExactEarth"</strong> Dr. Vicky
        Liau, Senior Software Engineer at TileDB, presented her analysis, combining analysis-ready data on TileDB Cloud.
      </>
    ),
    linkText: 'Watch the video',
    link: 'https://youtu.be/9X2Yv-7485w?t=3622',
    icon: videoIcon,
  },
  {
    title: 'Tutorials',
    text: (
      <>
        Already purchased exactEarth data? Access the demo notebooks on TileDB Cloud. Just launch a geospatial notebook and, once in the
        Jupyter Lab environment, use the file browser to access <strong>/examples/geo/exactEarth</strong> to get started.
      </>
    ),
    linkText: 'Log into TileDB Cloud',
    link: links.console.login,
    icon: tutorialIcon,
  },
];

const AISResources: React.FC = () => {
  return <Resources items={items} />;
};

export default AISResources;
