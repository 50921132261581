import * as React from 'react';
import CloudAnalysis from '../../common/CloudAnalysis';
import analysisReady from './assets/analysis-ready.png';
import timeSeries from './assets/time-series.png';
import dataProducts from './assets/data-products.png';

const items = [
  {
    title: 'Analysis-ready access',
    description:
      'No more distributing multiple copies of proprietary data. Maintain and securely govern one copy of cloud-native, analysis-ready AIS data.',
    icon: analysisReady,
  },
  {
    title: 'Time series at scale',
    description:
      'TileDB Cloud enables precise track analysis of maritime data. Scale beyond TBs with location data at sub-second granularity, directly accessible with versioning and time traveling.',
    icon: timeSeries,
  },
  {
    title: 'Data products with ease',
    description:
      'Monetize data and code without additional infrastructure, creating a sustainable marketplace for data producers and consumers alike.',
    icon: dataProducts,
  },
];

const AISCloudAnalysis: React.FC = () => (
  <CloudAnalysis
    title="Disrupting the distribution 
    and analysis of AIS data"
    items={items}
    className="CloudAnalysis--AIS"
  />
);

export default AISCloudAnalysis;
